/* Sudhanshu Style  */
body {
  height: 100vh !important;
  margin: 0px !important;
}
.whitecolortypography {
  color: #ffffff !important;
  font-family: "Play", sans-serif !important;
  font-weight: 400 !important;
}
.whitebackground {
  background-color: #fff !important;
}
.bluecolortypo {
  color: #32325d !important;
  font-family: "Play", sans-serif !important;
  font-weight: 700 !important;
}
.bluecolortypodong {
  color: #32325d !important;
  font-family: "Dongle", sans-serif !important;
  font-weight: 700 !important;
}
.bluecolortypo400 {
  color: #32325d !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
}
.darkblacktypo {
  color: rgba(54, 59, 73, 1) !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 700 !important;
}
.greycolortypo {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 700 !important;
  color: rgba(119, 119, 119, 1) !important;
}
.bluebutton {
  background-color: #05254c !important;
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.p-20px {
  padding: 20px !important;
}
.padding3-20px {
  padding: 3px 19px !important;
}
.padding3-30px {
  padding: 3px 30px !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-8px {
  margin-top: 8px !important;
}

.ml-10px {
  margin-left: 10px !important;
}

.width100 {
  width: 100% !important;
}
/* Sign In Component */
.imglogin {
  background: rgba(255, 255, 255, 0.1) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px 6px 6px 0px !important;
}
